// COLORS
// $primary-color: #1c92d2;
// $secondary-color: #f2fcfe;

$primary-color: #02aab0;
$secondary-color: #00cdac;

$white-color: #fff;

$dark-grey: #333333;
$light-grey: #d2d2d2;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
